import { createApp } from 'vue'

import JournalEntry from '@/components/JournalEntry.vue';
import JournalEntryRadio from '@/components/JournalEntryRadio.vue';
import JournalPageOncology from "@/components/JournalPageOncology.vue";
import QuestionTimeActivity01 from "@/components/QuestionTimeActivity01.vue";

Array.from(document.getElementsByClassName("journal-app")).forEach((el) => {
    createApp({}).component("journal-entry", JournalEntry).component("journal-entry-radio", JournalEntryRadio).mount(el);
});


Array.from(document.getElementsByClassName("journal-page")).forEach((el) => {
    createApp({}).component("journal-page-oncology", JournalPageOncology).mount(el);
});

Array.from(document.getElementsByClassName("question-time-radio-button")).forEach((el) => {
    createApp({}).component("question-time-activity01", QuestionTimeActivity01).mount(el);
});
